import {useMutation, UseMutationOptions, useQuery, UseQueryOptions} from '@tanstack/react-query';

import {fetcher} from './fetcher';

/*
 * THIS FILE IS AUTO-GENERATED BY graphql-codegen. DO NOT EDIT IT MANUALLY, YOUR CHANGES WILL BE OVERWRITTEN.
 * To update this file, edit `typedefs.graphql` or `operations.graphql` and run: `npm run codegen`
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  File: any;
};

export type AddScenarioInput = {
  logo?: InputMaybe<Base64File>;
  name: Scalars['String'];
};

export type AddScoreResult = {
  entries: Array<GameScore>;
  entryId: Scalars['ID'];
};

export type Base64File = {
  data: Scalars['String'];
  name: Scalars['String'];
};

export type Costs = {
  clearfell: Maybe<Array<CropCustomCost>>;
  establishmentLand: Maybe<Array<CropCustomCost>>;
  establishmentPest: Maybe<Array<CropCustomCost>>;
  establishmentPlanting: Maybe<Array<CropCustomCost>>;
  establishmentRemedial: Maybe<Array<CropCustomCost>>;
  establishmentWeed: Maybe<Array<CropCustomCost>>;
  growth: Maybe<Array<CropCustomCost>>;
  harvestHarvesting: Maybe<Array<CropCustomCost>>;
  harvestTransport: Maybe<Array<CropCustomCost>>;
  managementAnnual: Maybe<Array<CropCustomCost>>;
  managementEstablishment: Maybe<Array<CropCustomCost>>;
  yearOneAndTwo: Maybe<Array<CropCustomCost>>;
};

export type CostsInput = {
  clearfell?: InputMaybe<Array<CropCustomCostInput>>;
  establishmentLand?: InputMaybe<Array<CropCustomCostInput>>;
  establishmentPest?: InputMaybe<Array<CropCustomCostInput>>;
  establishmentPlanting?: InputMaybe<Array<CropCustomCostInput>>;
  establishmentRemedial?: InputMaybe<Array<CropCustomCostInput>>;
  establishmentWeed?: InputMaybe<Array<CropCustomCostInput>>;
  growth?: InputMaybe<Array<CropCustomCostInput>>;
  harvestHarvesting?: InputMaybe<Array<CropCustomCostInput>>;
  harvestTransport?: InputMaybe<Array<CropCustomCostInput>>;
  managementAnnual?: InputMaybe<Array<CropCustomCostInput>>;
  managementEstablishment?: InputMaybe<Array<CropCustomCostInput>>;
  yearOneAndTwo?: InputMaybe<Array<CropCustomCostInput>>;
};

export type CreateUserInput = {
  consentNews?: InputMaybe<Scalars['Boolean']>;
  consentPrivacy?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type CropCustomCost = {
  cropId: Scalars['String'];
  value: Maybe<Array<CustomCost>>;
};

export type CropCustomCostInput = {
  cropId: Scalars['String'];
  value?: InputMaybe<Array<CustomCostInput>>;
};

export type CropFloat = {
  cropId: Scalars['String'];
  value: Maybe<Scalars['Float']>;
};

export type CropFloatArray = {
  cropId: Scalars['String'];
  value: Maybe<Array<Scalars['Float']>>;
};

export type CropFloatArrayInput = {
  cropId: Scalars['String'];
  value?: InputMaybe<Array<Scalars['Float']>>;
};

export type CropFloatInput = {
  cropId: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export type CropInt = {
  cropId: Scalars['String'];
  value: Maybe<Scalars['Int']>;
};

export type CropIntInput = {
  cropId: Scalars['String'];
  value?: InputMaybe<Scalars['Int']>;
};

export type CustomCost = {
  cost: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  repetitions: Scalars['Int'];
};

export type CustomCostInput = {
  cost?: InputMaybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  repetitions: Scalars['Int'];
};

export type EditScenarioInput = {
  logo?: InputMaybe<Base64File>;
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum FarmerProfile {
  Interested = 'interested',
  Purchaser = 'purchaser',
  Researcher = 'researcher',
  Supplier = 'supplier',
}

export enum FarmerType {
  Farmer = 'farmer',
  LandOwner = 'landOwner',
  Neither = 'neither',
}

export enum Flow {
  Cost = 'cost',
  Land = 'land',
}

export type Game = {
  settings: GameSettings;
};

export type GameScore = {
  iconId: Scalars['String'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  money: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  production: Scalars['Float'];
  uuid: Scalars['ID'];
};

export type GameSettings = {
  audioEnabled: Scalars['Boolean'];
  showToolsMenu: Scalars['Boolean'];
};

export type MarketplaceCropType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MarketplaceServiceType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MarketplaceTag = {
  id: Scalars['ID'];
  tag: Scalars['String'];
};

export type Media = {
  alt: Maybe<Scalars['String']>;
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ModelData = {
  canPlant: Maybe<Scalars['Boolean']>;
  miscanthusYield: Maybe<Scalars['Float']>;
  rcgYield: Maybe<Scalars['Float']>;
  willowYield: Maybe<Scalars['Float']>;
};

export type Mutation = {
  addGameScore: AddScoreResult;
  addProduct: Product;
  addScenario: Scalars['String'];
  addSupplier: Supplier;
  createUser: Scalars['String'];
  deleteProduct: Maybe<Scalars['Boolean']>;
  editScenario: Scalars['Boolean'];
  publishSupplier: Maybe<Scalars['Boolean']>;
  removeScenario: Scalars['Boolean'];
  resetScenario: Scalars['Boolean'];
  resetScenarios: Scalars['Boolean'];
  setTracking: Scalars['Boolean'];
  updateInputs: Scalars['Boolean'];
  updateProduct: Product;
  updateProductOrder: Array<Product>;
  updateSupplier: Supplier;
  updateUser: User;
  updateUserProps: Scalars['Boolean'];
  uploadMedia: Media;
};

export type MutationAddGameScoreArgs = {
  iconId: Scalars['String'];
  money: Scalars['Int'];
  name: Scalars['String'];
  production: Scalars['Float'];
};

export type MutationAddProductArgs = {
  draft: Scalars['Boolean'];
  product: ProductInput;
  supplierId: Scalars['ID'];
};

export type MutationAddScenarioArgs = {
  scenario: AddScenarioInput;
};

export type MutationAddSupplierArgs = {
  supplier: SupplierInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};

export type MutationEditScenarioArgs = {
  scenario: EditScenarioInput;
};

export type MutationPublishSupplierArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveScenarioArgs = {
  uuid: Scalars['String'];
};

export type MutationResetScenarioArgs = {
  uuid: Scalars['String'];
};

export type MutationSetTrackingArgs = {
  scenarioId: Scalars['String'];
  tracking: TrackingInput;
};

export type MutationUpdateInputsArgs = {
  inputs: ScenarioInputsInput;
  scenarioId: Scalars['String'];
};

export type MutationUpdateProductArgs = {
  draft: Scalars['Boolean'];
  id: Scalars['ID'];
  product: ProductInput;
};

export type MutationUpdateProductOrderArgs = {
  productIds: Array<Scalars['ID']>;
  supplierId: Scalars['ID'];
};

export type MutationUpdateSupplierArgs = {
  id: Scalars['ID'];
  supplier: SupplierInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserPropsArgs = {
  data: UserPropsInput;
};

export type MutationUploadMediaArgs = {
  file: Scalars['File'];
};

export type Point = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum PowerCapacityId {
  Large50 = 'large50',
  Medium30 = 'medium30',
  Small05 = 'small05',
  Small10 = 'small10',
}

export type Product = {
  cropTypes: Array<MarketplaceCropType>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Maybe<Media>;
  name: Scalars['String'];
  promoted_until: Maybe<Scalars['String']>;
  published: Maybe<Scalars['Boolean']>;
  serviceType: Maybe<MarketplaceServiceType>;
  supplierId: Maybe<Scalars['ID']>;
};

export type ProductInput = {
  crop_types?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  promoted_until?: InputMaybe<Scalars['String']>;
  service_type?: InputMaybe<Scalars['String']>;
};

export type ProductSearchParams = {
  cropTypes?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<PointInput>;
  page?: InputMaybe<Scalars['Int']>;
  postcode?: InputMaybe<Scalars['String']>;
  proximity?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  serviceTypes?: InputMaybe<Array<Scalars['String']>>;
  sort?: InputMaybe<Scalars['String']>;
  suppliers?: InputMaybe<Array<Scalars['String']>>;
};

export enum PropertyId {
  CareHome = 'careHome',
  Glasshouses = 'glasshouses',
  GolfClubhouse = 'golfClubhouse',
  House3beds = 'house3beds',
  House5beds = 'house5beds',
  LargeFarmhouse = 'largeFarmhouse',
  NetworkFarmhouse = 'networkFarmhouse',
  PrimarySchool = 'primarySchool',
  SecondarySchool = 'secondarySchool',
  SmallPoultry = 'smallPoultry',
  VillageHeating = 'villageHeating',
}

export type Query = {
  currentUser: Maybe<User>;
  game: Game;
  gameScores: Array<GameScore>;
  modelData: Maybe<ModelData>;
  ownSupplier: Maybe<Supplier>;
  productSearch: SearchResult;
  scenario: Scenario;
  supplierSearch: Array<Scalars['String']>;
  test: Scalars['String'];
};

export type QueryGameScoresArgs = {
  myEntryId: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  sortByMoney: Scalars['Boolean'];
};

export type QueryModelDataArgs = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type QueryProductSearchArgs = {
  params: InputMaybe<ProductSearchParams>;
};

export type QueryScenarioArgs = {
  uuid: Scalars['String'];
};

export type QuerySupplierSearchArgs = {
  params: InputMaybe<SupplierSearchParams>;
};

export type Rating = {
  average: Scalars['Float'];
  count: Scalars['Int'];
};

export type Scenario = {
  inputs: ScenarioInputs;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tracking: Maybe<Tracking>;
  uuid: Scalars['String'];
};

export type ScenarioInfo = {
  createdAt: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ScenarioInputs = {
  alreadyPlanted: Maybe<Scalars['Boolean']>;
  annualBorrowRepayment: Maybe<Scalars['Float']>;
  annualBorrowing: Maybe<Scalars['Float']>;
  coordinates: Maybe<Point>;
  costEdited: Maybe<Scalars['Boolean']>;
  costFlowProgress: Maybe<Scalars['Float']>;
  costs: Maybe<Costs>;
  cv: Maybe<Array<CropFloat>>;
  fieldSizes: Maybe<Array<CropFloatArray>>;
  fuelRequirement: Maybe<Scalars['Float']>;
  goal: Maybe<UserGoal>;
  grants: Maybe<Scalars['Float']>;
  heatConsumption: Maybe<Scalars['Float']>;
  isBorrowing: Maybe<Scalars['Boolean']>;
  landEdited: Maybe<Scalars['Boolean']>;
  landFlowProgress: Maybe<Scalars['Float']>;
  landSize: Maybe<Scalars['Float']>;
  localYield: Maybe<Array<CropFloat>>;
  plantationLifetime: Maybe<Array<CropInt>>;
  plantedCropId: Maybe<Scalars['String']>;
  plantingYear: Maybe<Scalars['Float']>;
  postcode: Maybe<Scalars['String']>;
  powerCapacity: Maybe<PowerCapacityId>;
  propertyType: Maybe<PropertyId>;
  salePrice: Maybe<Array<CropFloat>>;
  sortBy: Maybe<Scalars['String']>;
  stagger: Maybe<Array<CropInt>>;
  subsidies: Maybe<Scalars['Float']>;
  upfrontBorrowing: Maybe<Scalars['Float']>;
};

export type ScenarioInputsInput = {
  alreadyPlanted?: InputMaybe<Scalars['Boolean']>;
  annualBorrowRepayment?: InputMaybe<Scalars['Float']>;
  annualBorrowing?: InputMaybe<Scalars['Float']>;
  coordinates?: InputMaybe<PointInput>;
  costEdited?: InputMaybe<Scalars['Boolean']>;
  costFlowProgress?: InputMaybe<Scalars['Float']>;
  costs?: InputMaybe<CostsInput>;
  cv?: InputMaybe<Array<CropFloatInput>>;
  fieldSizes?: InputMaybe<Array<CropFloatArrayInput>>;
  fuelRequirement?: InputMaybe<Scalars['Float']>;
  goal?: InputMaybe<Scalars['String']>;
  grants?: InputMaybe<Scalars['Float']>;
  heatConsumption?: InputMaybe<Scalars['Float']>;
  isBorrowing?: InputMaybe<Scalars['Boolean']>;
  landEdited?: InputMaybe<Scalars['Boolean']>;
  landFlowProgress?: InputMaybe<Scalars['Float']>;
  landSize?: InputMaybe<Scalars['Float']>;
  localYield?: InputMaybe<Array<CropFloatInput>>;
  plantationLifetime?: InputMaybe<Array<CropIntInput>>;
  plantedCropId?: InputMaybe<Scalars['String']>;
  plantingYear?: InputMaybe<Scalars['Float']>;
  postcode?: InputMaybe<Scalars['String']>;
  salePrice?: InputMaybe<Array<CropFloatInput>>;
  sortBy?: InputMaybe<Scalars['String']>;
  stagger?: InputMaybe<Array<CropIntInput>>;
  subsidies?: InputMaybe<Scalars['Float']>;
  upfrontBorrowing?: InputMaybe<Scalars['Float']>;
};

export type SearchResult = {
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  products: Array<Product>;
  total: Scalars['Int'];
};

export type Supplier = {
  about: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  approved: Maybe<Scalars['Boolean']>;
  google_place_id: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Maybe<Media>;
  image_fit: Maybe<Scalars['Boolean']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  owner_id: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  published: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  town: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
};

export type SupplierInput = {
  about?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  google_place_id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  image_fit?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type SupplierSearchParams = {
  cropTypes?: InputMaybe<Array<Scalars['String']>>;
  serviceTypes?: InputMaybe<Array<Scalars['String']>>;
  suppliers?: InputMaybe<Array<Scalars['String']>>;
};

export type Tracking = {
  plantedId: Scalars['String'];
  plantingYear: Scalars['Float'];
  years: Array<Maybe<TrackingYear>>;
};

export type TrackingInput = {
  plantedId: Scalars['String'];
  plantingYear: Scalars['Float'];
  years: Array<InputMaybe<TrackingYearInput>>;
};

export type TrackingYear = {
  yield: Scalars['Float'];
};

export type TrackingYearInput = {
  yield: Scalars['Float'];
};

export type UpdateUserInput = {
  consentNews?: InputMaybe<Scalars['Boolean']>;
  consentPrivacy?: InputMaybe<Scalars['Boolean']>;
  farmerProfile?: InputMaybe<Array<FarmerProfile>>;
  farmerType?: InputMaybe<FarmerType>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type User = {
  consentNews: Maybe<Scalars['Boolean']>;
  consentPrivacy: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  farmerProfile: Maybe<Array<FarmerProfile>>;
  farmerType: Maybe<FarmerType>;
  firstName: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  props: UserProps;
  role: UserRole;
  scenarioLimit: Scalars['Int'];
  scenarios: Array<ScenarioInfo>;
  uid: Scalars['String'];
};

export enum UserGoal {
  CultivateLand = 'cultivateLand',
  RunPowerStation = 'runPowerStation',
  SupplyProperty = 'supplyProperty',
}

export type UserProps = {
  prefersImperial: Maybe<Scalars['Boolean']>;
  supplierProfileLastSeen: Maybe<Scalars['String']>;
};

export type UserPropsInput = {
  prefersImperial?: InputMaybe<Scalars['Boolean']>;
  supplierProfileLastSeen?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Editor = 'editor',
  Viewer = 'viewer',
}

export type GameScoreFragmentFragment = {
  uuid: string;
  position: number;
  name: string;
  iconId: string;
  money: number;
  production: number;
  isFirst: boolean;
  isLast: boolean;
};

export type GameSettingsQueryVariables = Exact<{[key: string]: never}>;

export type GameSettingsQuery = {game: {settings: {audioEnabled: boolean; showToolsMenu: boolean}}};

export type GameScoresQueryVariables = Exact<{
  myEntryId: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  sortByMoney: Scalars['Boolean'];
}>;

export type GameScoresQuery = {
  gameScores: Array<{
    uuid: string;
    position: number;
    name: string;
    iconId: string;
    money: number;
    production: number;
    isFirst: boolean;
    isLast: boolean;
  }>;
};

export type AddGameScoreMutationVariables = Exact<{
  name: Scalars['String'];
  iconId: Scalars['String'];
  money: Scalars['Int'];
  production: Scalars['Float'];
}>;

export type AddGameScoreMutation = {
  addGameScore: {
    entryId: string;
    entries: Array<{
      uuid: string;
      position: number;
      name: string;
      iconId: string;
      money: number;
      production: number;
      isFirst: boolean;
      isLast: boolean;
    }>;
  };
};

export type MediaFieldsFragment = {id: string; url: string; alt: string | null};

export type SupplierFragmentFragment = {
  id: string;
  slug: string;
  name: string | null;
  website: string | null;
  phone: string | null;
  address: string | null;
  town: string | null;
  latitude: number | null;
  longitude: number | null;
  about: string | null;
  google_place_id: string | null;
  owner_id: string | null;
  published: boolean | null;
  approved: boolean | null;
  image_fit: boolean | null;
  image: {id: string; url: string; alt: string | null} | null;
};

export type ProductFragmentFragment = {
  id: string;
  name: string;
  description: string | null;
  promoted_until: string | null;
  published: boolean | null;
  supplierId: string | null;
  image: {id: string; url: string; alt: string | null} | null;
  cropTypes: Array<{id: string; name: string}>;
  serviceType: {id: string; name: string} | null;
};

export type AddSupplierMutationVariables = Exact<{
  supplier: SupplierInput;
}>;

export type AddSupplierMutation = {
  addSupplier: {
    id: string;
    slug: string;
    name: string | null;
    website: string | null;
    phone: string | null;
    address: string | null;
    town: string | null;
    latitude: number | null;
    longitude: number | null;
    about: string | null;
    google_place_id: string | null;
    owner_id: string | null;
    published: boolean | null;
    approved: boolean | null;
    image_fit: boolean | null;
    image: {id: string; url: string; alt: string | null} | null;
  };
};

export type UpdateSupplierMutationVariables = Exact<{
  id: Scalars['ID'];
  supplier: SupplierInput;
}>;

export type UpdateSupplierMutation = {
  updateSupplier: {
    id: string;
    slug: string;
    name: string | null;
    website: string | null;
    phone: string | null;
    address: string | null;
    town: string | null;
    latitude: number | null;
    longitude: number | null;
    about: string | null;
    google_place_id: string | null;
    owner_id: string | null;
    published: boolean | null;
    approved: boolean | null;
    image_fit: boolean | null;
    image: {id: string; url: string; alt: string | null} | null;
  };
};

export type UpdateProductOrderMutationVariables = Exact<{
  supplierId: Scalars['ID'];
  productIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateProductOrderMutation = {
  updateProductOrder: Array<{
    id: string;
    name: string;
    description: string | null;
    promoted_until: string | null;
    published: boolean | null;
    supplierId: string | null;
    image: {id: string; url: string; alt: string | null} | null;
    cropTypes: Array<{id: string; name: string}>;
    serviceType: {id: string; name: string} | null;
  }>;
};

export type PublishSupplierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishSupplierMutation = {publishSupplier: boolean | null};

export type FindProductsQueryVariables = Exact<{
  searchParams: InputMaybe<ProductSearchParams>;
}>;

export type FindProductsQuery = {
  productSearch: {
    total: number;
    page: number;
    pageCount: number;
    products: Array<{
      id: string;
      name: string;
      description: string | null;
      promoted_until: string | null;
      published: boolean | null;
      supplierId: string | null;
      image: {id: string; url: string; alt: string | null} | null;
      cropTypes: Array<{id: string; name: string}>;
      serviceType: {id: string; name: string} | null;
    }>;
  };
};

export type FindSuppliersQueryVariables = Exact<{
  searchParams: InputMaybe<SupplierSearchParams>;
}>;

export type FindSuppliersQuery = {supplierSearch: Array<string>};

export type OwnSupplierQueryVariables = Exact<{[key: string]: never}>;

export type OwnSupplierQuery = {
  ownSupplier: {
    id: string;
    slug: string;
    name: string | null;
    website: string | null;
    phone: string | null;
    address: string | null;
    town: string | null;
    latitude: number | null;
    longitude: number | null;
    about: string | null;
    google_place_id: string | null;
    owner_id: string | null;
    published: boolean | null;
    approved: boolean | null;
    image_fit: boolean | null;
    image: {id: string; url: string; alt: string | null} | null;
  } | null;
};

export type AddProductMutationVariables = Exact<{
  product: ProductInput;
  supplierId: Scalars['ID'];
  draft: Scalars['Boolean'];
}>;

export type AddProductMutation = {
  addProduct: {
    id: string;
    name: string;
    description: string | null;
    promoted_until: string | null;
    published: boolean | null;
    supplierId: string | null;
    image: {id: string; url: string; alt: string | null} | null;
    cropTypes: Array<{id: string; name: string}>;
    serviceType: {id: string; name: string} | null;
  };
};

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['ID'];
  product: ProductInput;
  draft: Scalars['Boolean'];
}>;

export type UpdateProductMutation = {
  updateProduct: {
    id: string;
    name: string;
    description: string | null;
    promoted_until: string | null;
    published: boolean | null;
    supplierId: string | null;
    image: {id: string; url: string; alt: string | null} | null;
    cropTypes: Array<{id: string; name: string}>;
    serviceType: {id: string; name: string} | null;
  };
};

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProductMutation = {deleteProduct: boolean | null};

export type CurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type CurrentUserQuery = {
  currentUser: {
    email: string;
    firstName: string;
    lastName: string | null;
    role: UserRole;
    farmerType: FarmerType | null;
    farmerProfile: Array<FarmerProfile> | null;
    consentPrivacy: boolean | null;
    consentNews: boolean | null;
    scenarioLimit: number;
    scenarios: Array<{
      uuid: string;
      name: string;
      logo: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    }>;
    props: {supplierProfileLastSeen: string | null; prefersImperial: boolean | null};
  } | null;
};

export type ModelDataQueryVariables = Exact<{
  lat: Scalars['Float'];
  lon: Scalars['Float'];
}>;

export type ModelDataQuery = {
  modelData: {
    canPlant: boolean | null;
    miscanthusYield: number | null;
    willowYield: number | null;
    rcgYield: number | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserMutation = {createUser: string};

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    email: string;
    firstName: string;
    lastName: string | null;
    farmerType: FarmerType | null;
    farmerProfile: Array<FarmerProfile> | null;
    consentPrivacy: boolean | null;
    consentNews: boolean | null;
  };
};

export type UpdateUserPropsMutationVariables = Exact<{
  data: UserPropsInput;
}>;

export type UpdateUserPropsMutation = {updateUserProps: boolean};

export type UploadMediaMutationVariables = Exact<{
  file: Scalars['File'];
}>;

export type UploadMediaMutation = {uploadMedia: {id: string; url: string; alt: string | null}};

export type CropCustomCostFieldsFragment = {
  cropId: string;
  value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
};

export type ScenarioQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ScenarioQuery = {
  scenario: {
    uuid: string;
    name: string;
    logo: string | null;
    inputs: {
      goal: UserGoal | null;
      propertyType: PropertyId | null;
      heatConsumption: number | null;
      powerCapacity: PowerCapacityId | null;
      fuelRequirement: number | null;
      landSize: number | null;
      grants: number | null;
      subsidies: number | null;
      isBorrowing: boolean | null;
      annualBorrowing: number | null;
      upfrontBorrowing: number | null;
      annualBorrowRepayment: number | null;
      plantingYear: number | null;
      postcode: string | null;
      alreadyPlanted: boolean | null;
      plantedCropId: string | null;
      sortBy: string | null;
      cv: Array<{cropId: string; value: number | null}> | null;
      salePrice: Array<{cropId: string; value: number | null}> | null;
      costs: {
        establishmentLand: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        establishmentWeed: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        establishmentPlanting: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        establishmentPest: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        establishmentRemedial: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        yearOneAndTwo: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        growth: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        harvestHarvesting: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        harvestTransport: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        managementEstablishment: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        managementAnnual: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
        clearfell: Array<{
          cropId: string;
          value: Array<{id: string; cost: number | null; repetitions: number; enabled: boolean}> | null;
        }> | null;
      } | null;
      stagger: Array<{cropId: string; value: number | null}> | null;
      fieldSizes: Array<{cropId: string; value: Array<number> | null}> | null;
      localYield: Array<{cropId: string; value: number | null}> | null;
      plantationLifetime: Array<{cropId: string; value: number | null}> | null;
      coordinates: {latitude: number; longitude: number} | null;
    };
    tracking: {plantedId: string; plantingYear: number; years: Array<{yield: number} | null>} | null;
  };
};

export type FlowProgressQueryVariables = Exact<{
  scenarioId: Scalars['String'];
}>;

export type FlowProgressQuery = {
  scenario: {inputs: {landFlowProgress: number | null; costFlowProgress: number | null}};
};

export type FlowEditedQueryVariables = Exact<{
  scenarioId: Scalars['String'];
}>;

export type FlowEditedQuery = {scenario: {inputs: {landEdited: boolean | null; costEdited: boolean | null}}};

export type UpdateInputsMutationVariables = Exact<{
  scenarioId: Scalars['String'];
  inputs: ScenarioInputsInput;
}>;

export type UpdateInputsMutation = {updateInputs: boolean};

export type ResetScenariosMutationVariables = Exact<{[key: string]: never}>;

export type ResetScenariosMutation = {resetScenarios: boolean};

export type ResetScenarioMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ResetScenarioMutation = {resetScenario: boolean};

export type AddScenarioMutationVariables = Exact<{
  scenario: AddScenarioInput;
}>;

export type AddScenarioMutation = {addScenario: string};

export type EditScenarioMutationVariables = Exact<{
  scenario: EditScenarioInput;
}>;

export type EditScenarioMutation = {editScenario: boolean};

export type RemoveScenarioMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveScenarioMutation = {removeScenario: boolean};

export type SetTrackingMutationVariables = Exact<{
  scenarioId: Scalars['String'];
  tracking: TrackingInput;
}>;

export type SetTrackingMutation = {setTracking: boolean};

export const GameScoreFragmentFragmentDoc = `
    fragment GameScoreFragment on GameScore {
  uuid
  position
  name
  iconId
  money
  production
  isFirst
  isLast
}
    `;
export const MediaFieldsFragmentDoc = `
    fragment MediaFields on Media {
  id
  url
  alt
}
    `;
export const SupplierFragmentFragmentDoc = `
    fragment SupplierFragment on Supplier {
  id
  slug
  name
  website
  phone
  address
  town
  latitude
  longitude
  about
  google_place_id
  owner_id
  published
  approved
  image {
    ...MediaFields
  }
  image_fit
}
    ${MediaFieldsFragmentDoc}`;
export const ProductFragmentFragmentDoc = `
    fragment ProductFragment on Product {
  id
  name
  description
  image {
    ...MediaFields
  }
  promoted_until
  published
  cropTypes {
    id
    name
  }
  serviceType {
    id
    name
  }
  supplierId
}
    ${MediaFieldsFragmentDoc}`;
export const CropCustomCostFieldsFragmentDoc = `
    fragment CropCustomCostFields on CropCustomCost {
  cropId
  value {
    id
    cost
    repetitions
    enabled
  }
}
    `;
export const GameSettingsDocument = `
    query GameSettings {
  game {
    settings {
      audioEnabled
      showToolsMenu
    }
  }
}
    `;
export const useGameSettingsQuery = <TData = GameSettingsQuery, TError = unknown>(
  variables?: GameSettingsQueryVariables,
  options?: UseQueryOptions<GameSettingsQuery, TError, TData>
) =>
  useQuery<GameSettingsQuery, TError, TData>(
    variables === undefined ? ['GameSettings'] : ['GameSettings', variables],
    fetcher<GameSettingsQuery, GameSettingsQueryVariables>(GameSettingsDocument, variables),
    options
  );

useGameSettingsQuery.getKey = (variables?: GameSettingsQueryVariables) =>
  variables === undefined ? ['GameSettings'] : ['GameSettings', variables];
useGameSettingsQuery.fetcher = (variables?: GameSettingsQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GameSettingsQuery, GameSettingsQueryVariables>(GameSettingsDocument, variables, options);
export const GameScoresDocument = `
    query GameScores($myEntryId: ID, $page: Int!, $sortByMoney: Boolean!) {
  gameScores(myEntryId: $myEntryId, page: $page, sortByMoney: $sortByMoney) {
    ...GameScoreFragment
  }
}
    ${GameScoreFragmentFragmentDoc}`;
export const useGameScoresQuery = <TData = GameScoresQuery, TError = unknown>(
  variables: GameScoresQueryVariables,
  options?: UseQueryOptions<GameScoresQuery, TError, TData>
) =>
  useQuery<GameScoresQuery, TError, TData>(
    ['GameScores', variables],
    fetcher<GameScoresQuery, GameScoresQueryVariables>(GameScoresDocument, variables),
    options
  );

useGameScoresQuery.getKey = (variables: GameScoresQueryVariables) => ['GameScores', variables];
useGameScoresQuery.fetcher = (variables: GameScoresQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GameScoresQuery, GameScoresQueryVariables>(GameScoresDocument, variables, options);
export const AddGameScoreDocument = `
    mutation AddGameScore($name: String!, $iconId: String!, $money: Int!, $production: Float!) {
  addGameScore(
    name: $name
    iconId: $iconId
    money: $money
    production: $production
  ) {
    entryId
    entries {
      ...GameScoreFragment
    }
  }
}
    ${GameScoreFragmentFragmentDoc}`;
export const useAddGameScoreMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddGameScoreMutation, TError, AddGameScoreMutationVariables, TContext>
) =>
  useMutation<AddGameScoreMutation, TError, AddGameScoreMutationVariables, TContext>(
    ['AddGameScore'],
    (variables?: AddGameScoreMutationVariables) =>
      fetcher<AddGameScoreMutation, AddGameScoreMutationVariables>(AddGameScoreDocument, variables)(),
    options
  );
useAddGameScoreMutation.fetcher = (variables: AddGameScoreMutationVariables, options?: RequestInit['headers']) =>
  fetcher<AddGameScoreMutation, AddGameScoreMutationVariables>(AddGameScoreDocument, variables, options);
export const AddSupplierDocument = `
    mutation AddSupplier($supplier: SupplierInput!) {
  addSupplier(supplier: $supplier) {
    ...SupplierFragment
  }
}
    ${SupplierFragmentFragmentDoc}`;
export const useAddSupplierMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddSupplierMutation, TError, AddSupplierMutationVariables, TContext>
) =>
  useMutation<AddSupplierMutation, TError, AddSupplierMutationVariables, TContext>(
    ['AddSupplier'],
    (variables?: AddSupplierMutationVariables) =>
      fetcher<AddSupplierMutation, AddSupplierMutationVariables>(AddSupplierDocument, variables)(),
    options
  );
useAddSupplierMutation.fetcher = (variables: AddSupplierMutationVariables, options?: RequestInit['headers']) =>
  fetcher<AddSupplierMutation, AddSupplierMutationVariables>(AddSupplierDocument, variables, options);
export const UpdateSupplierDocument = `
    mutation UpdateSupplier($id: ID!, $supplier: SupplierInput!) {
  updateSupplier(id: $id, supplier: $supplier) {
    ...SupplierFragment
  }
}
    ${SupplierFragmentFragmentDoc}`;
export const useUpdateSupplierMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateSupplierMutation, TError, UpdateSupplierMutationVariables, TContext>
) =>
  useMutation<UpdateSupplierMutation, TError, UpdateSupplierMutationVariables, TContext>(
    ['UpdateSupplier'],
    (variables?: UpdateSupplierMutationVariables) =>
      fetcher<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, variables)(),
    options
  );
useUpdateSupplierMutation.fetcher = (variables: UpdateSupplierMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, variables, options);
export const UpdateProductOrderDocument = `
    mutation UpdateProductOrder($supplierId: ID!, $productIds: [ID!]!) {
  updateProductOrder(supplierId: $supplierId, productIds: $productIds) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;
export const useUpdateProductOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateProductOrderMutation, TError, UpdateProductOrderMutationVariables, TContext>
) =>
  useMutation<UpdateProductOrderMutation, TError, UpdateProductOrderMutationVariables, TContext>(
    ['UpdateProductOrder'],
    (variables?: UpdateProductOrderMutationVariables) =>
      fetcher<UpdateProductOrderMutation, UpdateProductOrderMutationVariables>(UpdateProductOrderDocument, variables)(),
    options
  );
useUpdateProductOrderMutation.fetcher = (
  variables: UpdateProductOrderMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UpdateProductOrderMutation, UpdateProductOrderMutationVariables>(
    UpdateProductOrderDocument,
    variables,
    options
  );
export const PublishSupplierDocument = `
    mutation PublishSupplier($id: ID!) {
  publishSupplier(id: $id)
}
    `;
export const usePublishSupplierMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<PublishSupplierMutation, TError, PublishSupplierMutationVariables, TContext>
) =>
  useMutation<PublishSupplierMutation, TError, PublishSupplierMutationVariables, TContext>(
    ['PublishSupplier'],
    (variables?: PublishSupplierMutationVariables) =>
      fetcher<PublishSupplierMutation, PublishSupplierMutationVariables>(PublishSupplierDocument, variables)(),
    options
  );
usePublishSupplierMutation.fetcher = (variables: PublishSupplierMutationVariables, options?: RequestInit['headers']) =>
  fetcher<PublishSupplierMutation, PublishSupplierMutationVariables>(PublishSupplierDocument, variables, options);
export const FindProductsDocument = `
    query FindProducts($searchParams: ProductSearchParams) {
  productSearch(params: $searchParams) {
    total
    page
    pageCount
    products {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}`;
export const useFindProductsQuery = <TData = FindProductsQuery, TError = unknown>(
  variables?: FindProductsQueryVariables,
  options?: UseQueryOptions<FindProductsQuery, TError, TData>
) =>
  useQuery<FindProductsQuery, TError, TData>(
    variables === undefined ? ['FindProducts'] : ['FindProducts', variables],
    fetcher<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, variables),
    options
  );

useFindProductsQuery.getKey = (variables?: FindProductsQueryVariables) =>
  variables === undefined ? ['FindProducts'] : ['FindProducts', variables];
useFindProductsQuery.fetcher = (variables?: FindProductsQueryVariables, options?: RequestInit['headers']) =>
  fetcher<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, variables, options);
export const FindSuppliersDocument = `
    query FindSuppliers($searchParams: SupplierSearchParams) {
  supplierSearch(params: $searchParams)
}
    `;
export const useFindSuppliersQuery = <TData = FindSuppliersQuery, TError = unknown>(
  variables?: FindSuppliersQueryVariables,
  options?: UseQueryOptions<FindSuppliersQuery, TError, TData>
) =>
  useQuery<FindSuppliersQuery, TError, TData>(
    variables === undefined ? ['FindSuppliers'] : ['FindSuppliers', variables],
    fetcher<FindSuppliersQuery, FindSuppliersQueryVariables>(FindSuppliersDocument, variables),
    options
  );

useFindSuppliersQuery.getKey = (variables?: FindSuppliersQueryVariables) =>
  variables === undefined ? ['FindSuppliers'] : ['FindSuppliers', variables];
useFindSuppliersQuery.fetcher = (variables?: FindSuppliersQueryVariables, options?: RequestInit['headers']) =>
  fetcher<FindSuppliersQuery, FindSuppliersQueryVariables>(FindSuppliersDocument, variables, options);
export const OwnSupplierDocument = `
    query OwnSupplier {
  ownSupplier {
    ...SupplierFragment
  }
}
    ${SupplierFragmentFragmentDoc}`;
export const useOwnSupplierQuery = <TData = OwnSupplierQuery, TError = unknown>(
  variables?: OwnSupplierQueryVariables,
  options?: UseQueryOptions<OwnSupplierQuery, TError, TData>
) =>
  useQuery<OwnSupplierQuery, TError, TData>(
    variables === undefined ? ['OwnSupplier'] : ['OwnSupplier', variables],
    fetcher<OwnSupplierQuery, OwnSupplierQueryVariables>(OwnSupplierDocument, variables),
    options
  );

useOwnSupplierQuery.getKey = (variables?: OwnSupplierQueryVariables) =>
  variables === undefined ? ['OwnSupplier'] : ['OwnSupplier', variables];
useOwnSupplierQuery.fetcher = (variables?: OwnSupplierQueryVariables, options?: RequestInit['headers']) =>
  fetcher<OwnSupplierQuery, OwnSupplierQueryVariables>(OwnSupplierDocument, variables, options);
export const AddProductDocument = `
    mutation AddProduct($product: ProductInput!, $supplierId: ID!, $draft: Boolean!) {
  addProduct(product: $product, supplierId: $supplierId, draft: $draft) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;
export const useAddProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddProductMutation, TError, AddProductMutationVariables, TContext>
) =>
  useMutation<AddProductMutation, TError, AddProductMutationVariables, TContext>(
    ['AddProduct'],
    (variables?: AddProductMutationVariables) =>
      fetcher<AddProductMutation, AddProductMutationVariables>(AddProductDocument, variables)(),
    options
  );
useAddProductMutation.fetcher = (variables: AddProductMutationVariables, options?: RequestInit['headers']) =>
  fetcher<AddProductMutation, AddProductMutationVariables>(AddProductDocument, variables, options);
export const UpdateProductDocument = `
    mutation UpdateProduct($id: ID!, $product: ProductInput!, $draft: Boolean!) {
  updateProduct(id: $id, product: $product, draft: $draft) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;
export const useUpdateProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>
) =>
  useMutation<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>(
    ['UpdateProduct'],
    (variables?: UpdateProductMutationVariables) =>
      fetcher<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, variables)(),
    options
  );
useUpdateProductMutation.fetcher = (variables: UpdateProductMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, variables, options);
export const DeleteProductDocument = `
    mutation DeleteProduct($id: ID!) {
  deleteProduct(id: $id)
}
    `;
export const useDeleteProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>
) =>
  useMutation<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>(
    ['DeleteProduct'],
    (variables?: DeleteProductMutationVariables) =>
      fetcher<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, variables)(),
    options
  );
useDeleteProductMutation.fetcher = (variables: DeleteProductMutationVariables, options?: RequestInit['headers']) =>
  fetcher<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, variables, options);
export const CurrentUserDocument = `
    query CurrentUser {
  currentUser {
    email
    firstName
    lastName
    role
    farmerType
    farmerProfile
    consentPrivacy
    consentNews
    scenarios {
      uuid
      name
      logo
      createdAt
      updatedAt
    }
    scenarioLimit
    props {
      supplierProfileLastSeen
      prefersImperial
    }
  }
}
    `;
export const useCurrentUserQuery = <TData = CurrentUserQuery, TError = unknown>(
  variables?: CurrentUserQueryVariables,
  options?: UseQueryOptions<CurrentUserQuery, TError, TData>
) =>
  useQuery<CurrentUserQuery, TError, TData>(
    variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
    fetcher<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables),
    options
  );

useCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) =>
  variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables];
useCurrentUserQuery.fetcher = (variables?: CurrentUserQueryVariables, options?: RequestInit['headers']) =>
  fetcher<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables, options);
export const ModelDataDocument = `
    query ModelData($lat: Float!, $lon: Float!) {
  modelData(lat: $lat, lon: $lon) {
    canPlant
    miscanthusYield
    willowYield
    rcgYield
  }
}
    `;
export const useModelDataQuery = <TData = ModelDataQuery, TError = unknown>(
  variables: ModelDataQueryVariables,
  options?: UseQueryOptions<ModelDataQuery, TError, TData>
) =>
  useQuery<ModelDataQuery, TError, TData>(
    ['ModelData', variables],
    fetcher<ModelDataQuery, ModelDataQueryVariables>(ModelDataDocument, variables),
    options
  );

useModelDataQuery.getKey = (variables: ModelDataQueryVariables) => ['ModelData', variables];
useModelDataQuery.fetcher = (variables: ModelDataQueryVariables, options?: RequestInit['headers']) =>
  fetcher<ModelDataQuery, ModelDataQueryVariables>(ModelDataDocument, variables, options);
export const CreateUserDocument = `
    mutation CreateUser($data: CreateUserInput!) {
  createUser(data: $data)
}
    `;
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>
) =>
  useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
    ['CreateUser'],
    (variables?: CreateUserMutationVariables) =>
      fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
    options
  );
useCreateUserMutation.fetcher = (variables: CreateUserMutationVariables, options?: RequestInit['headers']) =>
  fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables, options);
export const UpdateUserDocument = `
    mutation UpdateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    email
    firstName
    lastName
    farmerType
    farmerProfile
    consentPrivacy
    consentNews
  }
}
    `;
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>
) =>
  useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
    ['UpdateUser'],
    (variables?: UpdateUserMutationVariables) =>
      fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    options
  );
useUpdateUserMutation.fetcher = (variables: UpdateUserMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options);
export const UpdateUserPropsDocument = `
    mutation UpdateUserProps($data: UserPropsInput!) {
  updateUserProps(data: $data)
}
    `;
export const useUpdateUserPropsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateUserPropsMutation, TError, UpdateUserPropsMutationVariables, TContext>
) =>
  useMutation<UpdateUserPropsMutation, TError, UpdateUserPropsMutationVariables, TContext>(
    ['UpdateUserProps'],
    (variables?: UpdateUserPropsMutationVariables) =>
      fetcher<UpdateUserPropsMutation, UpdateUserPropsMutationVariables>(UpdateUserPropsDocument, variables)(),
    options
  );
useUpdateUserPropsMutation.fetcher = (variables: UpdateUserPropsMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateUserPropsMutation, UpdateUserPropsMutationVariables>(UpdateUserPropsDocument, variables, options);
export const UploadMediaDocument = `
    mutation UploadMedia($file: File!) {
  uploadMedia(file: $file) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;
export const useUploadMediaMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UploadMediaMutation, TError, UploadMediaMutationVariables, TContext>
) =>
  useMutation<UploadMediaMutation, TError, UploadMediaMutationVariables, TContext>(
    ['UploadMedia'],
    (variables?: UploadMediaMutationVariables) =>
      fetcher<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, variables)(),
    options
  );
useUploadMediaMutation.fetcher = (variables: UploadMediaMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, variables, options);
export const ScenarioDocument = `
    query Scenario($uuid: String!) {
  scenario(uuid: $uuid) {
    inputs {
      goal
      propertyType
      heatConsumption
      powerCapacity
      fuelRequirement
      landSize
      cv {
        cropId
        value
      }
      salePrice {
        cropId
        value
      }
      grants
      subsidies
      isBorrowing
      annualBorrowing
      upfrontBorrowing
      annualBorrowRepayment
      costs {
        establishmentLand {
          ...CropCustomCostFields
        }
        establishmentWeed {
          ...CropCustomCostFields
        }
        establishmentPlanting {
          ...CropCustomCostFields
        }
        establishmentPest {
          ...CropCustomCostFields
        }
        establishmentRemedial {
          ...CropCustomCostFields
        }
        yearOneAndTwo {
          ...CropCustomCostFields
        }
        growth {
          ...CropCustomCostFields
        }
        harvestHarvesting {
          ...CropCustomCostFields
        }
        harvestTransport {
          ...CropCustomCostFields
        }
        managementEstablishment {
          ...CropCustomCostFields
        }
        managementAnnual {
          ...CropCustomCostFields
        }
        clearfell {
          ...CropCustomCostFields
        }
      }
      stagger {
        cropId
        value
      }
      fieldSizes {
        cropId
        value
      }
      localYield {
        cropId
        value
      }
      plantationLifetime {
        cropId
        value
      }
      plantingYear
      postcode
      coordinates {
        latitude
        longitude
      }
      alreadyPlanted
      plantedCropId
      sortBy
    }
    uuid
    name
    logo
    tracking {
      plantedId
      plantingYear
      years {
        yield
      }
    }
  }
}
    ${CropCustomCostFieldsFragmentDoc}`;
export const useScenarioQuery = <TData = ScenarioQuery, TError = unknown>(
  variables: ScenarioQueryVariables,
  options?: UseQueryOptions<ScenarioQuery, TError, TData>
) =>
  useQuery<ScenarioQuery, TError, TData>(
    ['Scenario', variables],
    fetcher<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, variables),
    options
  );

useScenarioQuery.getKey = (variables: ScenarioQueryVariables) => ['Scenario', variables];
useScenarioQuery.fetcher = (variables: ScenarioQueryVariables, options?: RequestInit['headers']) =>
  fetcher<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, variables, options);
export const FlowProgressDocument = `
    query FlowProgress($scenarioId: String!) {
  scenario(uuid: $scenarioId) {
    inputs {
      landFlowProgress
      costFlowProgress
    }
  }
}
    `;
export const useFlowProgressQuery = <TData = FlowProgressQuery, TError = unknown>(
  variables: FlowProgressQueryVariables,
  options?: UseQueryOptions<FlowProgressQuery, TError, TData>
) =>
  useQuery<FlowProgressQuery, TError, TData>(
    ['FlowProgress', variables],
    fetcher<FlowProgressQuery, FlowProgressQueryVariables>(FlowProgressDocument, variables),
    options
  );

useFlowProgressQuery.getKey = (variables: FlowProgressQueryVariables) => ['FlowProgress', variables];
useFlowProgressQuery.fetcher = (variables: FlowProgressQueryVariables, options?: RequestInit['headers']) =>
  fetcher<FlowProgressQuery, FlowProgressQueryVariables>(FlowProgressDocument, variables, options);
export const FlowEditedDocument = `
    query FlowEdited($scenarioId: String!) {
  scenario(uuid: $scenarioId) {
    inputs {
      landEdited
      costEdited
    }
  }
}
    `;
export const useFlowEditedQuery = <TData = FlowEditedQuery, TError = unknown>(
  variables: FlowEditedQueryVariables,
  options?: UseQueryOptions<FlowEditedQuery, TError, TData>
) =>
  useQuery<FlowEditedQuery, TError, TData>(
    ['FlowEdited', variables],
    fetcher<FlowEditedQuery, FlowEditedQueryVariables>(FlowEditedDocument, variables),
    options
  );

useFlowEditedQuery.getKey = (variables: FlowEditedQueryVariables) => ['FlowEdited', variables];
useFlowEditedQuery.fetcher = (variables: FlowEditedQueryVariables, options?: RequestInit['headers']) =>
  fetcher<FlowEditedQuery, FlowEditedQueryVariables>(FlowEditedDocument, variables, options);
export const UpdateInputsDocument = `
    mutation UpdateInputs($scenarioId: String!, $inputs: ScenarioInputsInput!) {
  updateInputs(scenarioId: $scenarioId, inputs: $inputs)
}
    `;
export const useUpdateInputsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateInputsMutation, TError, UpdateInputsMutationVariables, TContext>
) =>
  useMutation<UpdateInputsMutation, TError, UpdateInputsMutationVariables, TContext>(
    ['UpdateInputs'],
    (variables?: UpdateInputsMutationVariables) =>
      fetcher<UpdateInputsMutation, UpdateInputsMutationVariables>(UpdateInputsDocument, variables)(),
    options
  );
useUpdateInputsMutation.fetcher = (variables: UpdateInputsMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateInputsMutation, UpdateInputsMutationVariables>(UpdateInputsDocument, variables, options);
export const ResetScenariosDocument = `
    mutation ResetScenarios {
  resetScenarios
}
    `;
export const useResetScenariosMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ResetScenariosMutation, TError, ResetScenariosMutationVariables, TContext>
) =>
  useMutation<ResetScenariosMutation, TError, ResetScenariosMutationVariables, TContext>(
    ['ResetScenarios'],
    (variables?: ResetScenariosMutationVariables) =>
      fetcher<ResetScenariosMutation, ResetScenariosMutationVariables>(ResetScenariosDocument, variables)(),
    options
  );
useResetScenariosMutation.fetcher = (variables?: ResetScenariosMutationVariables, options?: RequestInit['headers']) =>
  fetcher<ResetScenariosMutation, ResetScenariosMutationVariables>(ResetScenariosDocument, variables, options);
export const ResetScenarioDocument = `
    mutation ResetScenario($uuid: String!) {
  resetScenario(uuid: $uuid)
}
    `;
export const useResetScenarioMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ResetScenarioMutation, TError, ResetScenarioMutationVariables, TContext>
) =>
  useMutation<ResetScenarioMutation, TError, ResetScenarioMutationVariables, TContext>(
    ['ResetScenario'],
    (variables?: ResetScenarioMutationVariables) =>
      fetcher<ResetScenarioMutation, ResetScenarioMutationVariables>(ResetScenarioDocument, variables)(),
    options
  );
useResetScenarioMutation.fetcher = (variables: ResetScenarioMutationVariables, options?: RequestInit['headers']) =>
  fetcher<ResetScenarioMutation, ResetScenarioMutationVariables>(ResetScenarioDocument, variables, options);
export const AddScenarioDocument = `
    mutation AddScenario($scenario: AddScenarioInput!) {
  addScenario(scenario: $scenario)
}
    `;
export const useAddScenarioMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddScenarioMutation, TError, AddScenarioMutationVariables, TContext>
) =>
  useMutation<AddScenarioMutation, TError, AddScenarioMutationVariables, TContext>(
    ['AddScenario'],
    (variables?: AddScenarioMutationVariables) =>
      fetcher<AddScenarioMutation, AddScenarioMutationVariables>(AddScenarioDocument, variables)(),
    options
  );
useAddScenarioMutation.fetcher = (variables: AddScenarioMutationVariables, options?: RequestInit['headers']) =>
  fetcher<AddScenarioMutation, AddScenarioMutationVariables>(AddScenarioDocument, variables, options);
export const EditScenarioDocument = `
    mutation EditScenario($scenario: EditScenarioInput!) {
  editScenario(scenario: $scenario)
}
    `;
export const useEditScenarioMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EditScenarioMutation, TError, EditScenarioMutationVariables, TContext>
) =>
  useMutation<EditScenarioMutation, TError, EditScenarioMutationVariables, TContext>(
    ['EditScenario'],
    (variables?: EditScenarioMutationVariables) =>
      fetcher<EditScenarioMutation, EditScenarioMutationVariables>(EditScenarioDocument, variables)(),
    options
  );
useEditScenarioMutation.fetcher = (variables: EditScenarioMutationVariables, options?: RequestInit['headers']) =>
  fetcher<EditScenarioMutation, EditScenarioMutationVariables>(EditScenarioDocument, variables, options);
export const RemoveScenarioDocument = `
    mutation RemoveScenario($uuid: String!) {
  removeScenario(uuid: $uuid)
}
    `;
export const useRemoveScenarioMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RemoveScenarioMutation, TError, RemoveScenarioMutationVariables, TContext>
) =>
  useMutation<RemoveScenarioMutation, TError, RemoveScenarioMutationVariables, TContext>(
    ['RemoveScenario'],
    (variables?: RemoveScenarioMutationVariables) =>
      fetcher<RemoveScenarioMutation, RemoveScenarioMutationVariables>(RemoveScenarioDocument, variables)(),
    options
  );
useRemoveScenarioMutation.fetcher = (variables: RemoveScenarioMutationVariables, options?: RequestInit['headers']) =>
  fetcher<RemoveScenarioMutation, RemoveScenarioMutationVariables>(RemoveScenarioDocument, variables, options);
export const SetTrackingDocument = `
    mutation SetTracking($scenarioId: String!, $tracking: TrackingInput!) {
  setTracking(scenarioId: $scenarioId, tracking: $tracking)
}
    `;
export const useSetTrackingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SetTrackingMutation, TError, SetTrackingMutationVariables, TContext>
) =>
  useMutation<SetTrackingMutation, TError, SetTrackingMutationVariables, TContext>(
    ['SetTracking'],
    (variables?: SetTrackingMutationVariables) =>
      fetcher<SetTrackingMutation, SetTrackingMutationVariables>(SetTrackingDocument, variables)(),
    options
  );
useSetTrackingMutation.fetcher = (variables: SetTrackingMutationVariables, options?: RequestInit['headers']) =>
  fetcher<SetTrackingMutation, SetTrackingMutationVariables>(SetTrackingDocument, variables, options);
